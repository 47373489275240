
.nav-btn {
    border: 3px solid white;
    background: transparent;
    padding: 2em;
    color: white;
    width: 130px;
    position: absolute;
    font-family: $font-links;
    font-size: 1em;
    transition: 0.5s;
    cursor: pointer;
    text-decoration: none;
    z-index: 200;
    text-align: center;

    @media screen and (max-width: 700px) {
      padding: 1.5em;
      }
  
    &__tl,
    &__tr {
      top: 0;
      border-top: transparent;
    }
  
    &__tl {
      left: 0;
      border-left: transparent;
      border-radius: 0 0 8px 0;
    }
  
    &__tr {
      right: 0;
      border-right: transparent;
      border-radius: 0 0 0 8px;
    }
  
    &__bl,
    &__br {
      bottom: 0;
      border-bottom: transparent;
    }
  
    &__bl {
      left: 0;
      border-left: transparent;
      border-radius: 0 8px 0 0;
    }
  
    &__br {
      right: 0;
      border-right: transparent;
      border-radius: 8px 0 0 0;
    }
  
    &.active,
    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;
      font-weight: bold;
      color: white;
    }
  }

