body {
  font-family: $font-primary;
  background-image: $background-color;
  background-repeat: no-repeat;
  line-height: 1.5;
  background-position: center;
  background-size: cover;
  top: 0;
  bottom: 0;
  position: relative;
}

img {
  width: 100%;
  object-fit: cover;
}

h1 {
  margin-top: 0 !important;
}

.content {
  text-align: center;
  color: white;
  z-index: 100;
  overflow: hidden;
}

ul {
  list-style: none;
  text-align: left;
}

li {
  display: flex;
  gap: 10px;
}

//Blue custom bullet points
ul li::before {
  content: "\2022";
  color: $color-primary; /* Change the color */
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.loader-container {
  height: 160px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  text-align: center;
  color: white;
  background: #cc0000;
  padding: 0.7rem;
  max-width: fit-content;
  margin: 0 auto;
}

.no-data {
  margin: 0 auto;
  max-width: fit-content;
  display: flex;
}

