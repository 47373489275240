

  .project {
    height: 400px;
    color: white;
    transition: 1s;
    cursor: pointer;
    border-radius: 8px;
    position: relative;

    h2, h3 {
      transition: .7s;
    }

    &:hover h2, &:hover h3 {
        transform: scale(1.2);
    }
  }

  h2 {
      margin-bottom: 5px;
  }

  h3 {
      margin-top: 5px;
      font-weight: 400;
  }

  .hover-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    transition: .7s ease;
    height: 100%;
    border-radius: 8px;
  }

.hover-overlay:hover {
    background: $color-primary-hover;
    border: 15px solid $color-primary;
  }

