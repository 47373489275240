.contact {
  max-width: 700px;
  overflow-y: auto;

  a {
    color: white;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (min-width: 750px) {
  .contact {
    min-width: 600px;
    margin: 0 auto;
  }
}

.invalid-feedback {
  text-align: left;
}
