.accordion-item {
  color: black;

  svg {
    margin-right: 10px;
  }

  span {
    display: contents;
    font-style: italic;
  }

  &:first-child {
    border-radius: 8px 8px 0 0 !important;

    button {
      border-radius: 8px 8px 0 0 !important;
    }
  }

  &:last-child {
    border-radius: 0 0 8px 8px !important;

    button {
      border-radius: 0 0 8px 8px !important;
    }

    .accordion-button {
      &:not(.collapsed) {
        border-radius: 0 !important;
      }
    }
  }

  h2 {
    margin: 0;
  }

  .accordion-button {
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }

    &:not(.collapsed) {
      font-weight: bold;
    }
  }
}

.accordion.body:last-child {
  border-radius: 0 0 8px 8px !important;
}

@media screen and (min-width: 750px) {
  .accordion {
    display: none;
  }
}
