
.btn-submit {
  border: none;
  background-color: $color-primary;
  color: white;
  padding: 1.2em;
  border-radius: 8px;
  align-self: center;
  width: 100%;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: $font-links;

  &:hover {
    background-color: $color-primary-hover;
  }
}
