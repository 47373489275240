.nav-tabs {
  border-bottom: none;

  li::before {
    content: none;
  }
}

.nav-item {
  gap: 0;

  svg {
    margin-right: 10px;
  }

  &:first-child {
    button {
      border-top-left-radius: 8px;
      border-top-right-radius: 0;
    }
  }

  &:last-child {
    button {
      border-top-left-radius: 0;
      border-top-right-radius: 8px;
    }
  }
}

.nav-tabs .nav-link {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-link {
  border: 1px solid $color-primary !important;
  color: white;
  font-size: 1em;
  padding: 1rem;
  cursor: pointer;

  &.active {
    font-weight: bold;
  }

  &:hover {
    background: $color-primary;
    color: white;
  }
}

.tab-content {
  background: transparent;
  border-radius: 0 8px 8px 8px;
  color: #000;
  margin-top: 1px;
  height: 275px;

  ul {
    margin: 1px;

    span {
      display: contents;
      font-style: italic;
    }
  }
}

.tab-content > .active {
  background: white;
  padding: 1rem;
  border-radius: 0 8px 8px 8px;
}

@media screen and (max-width: 750px) {
  .nav-tabs,
  .tab-content {
    display: none;
  }
}
