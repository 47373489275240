* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6em 1em;
  height: 100%;
  overflow: hidden;
}

.intro {
  @mixin quotes($side, $topPercentage, $sidePercentage) {
    background: $color-primary;
    padding: 2rem;
    border-radius: 8px;
    color: white;
    position: absolute;
    min-width: 400px;
    font-weight: 600;
    font-style: italic;
    transition: 1.5s;
    #{$side}: $sidePercentage;
    top: $topPercentage;
  }

  .image-container {
    max-width: 33%;
    margin: 0 auto;
    position: relative;

    @media screen and (min-width: 665px) {
      max-width: 25%;
    }

    @media screen and (min-width: 850px) {
      max-width: 17%;
    }

    @media screen and (min-width: 1230px) {
      max-width: 9%;

      &:hover .quotes-left {
        @include quotes(right, 50%, 137%);
      }

      &:hover .quotes-right {
        @include quotes(left, 25%, 137%);
      }
    }
  }

  h1 {
    max-width: 450px;
    margin: 1em auto;
  }

  .quotes-left {
    @include quotes(right, 50%, 700%);
  }

  .quotes-right {
    @include quotes(left, 25%, 700%);
  }
}
