h1,
h4 {
  color: $color-primary;
  text-align: center;
}

h1 {
  font-weight: 600;
  font-size: 1.4em;
  margin-top: 0;
}

h2 {
  font-size: 1.3em;
}

h4 {
  font-size: 1.2em;
  margin: 0;
}

h5 {
  font-size: 1em;
  margin-bottom: 1em;
}

@media screen and (min-width: 750px) {
  h1 {
    font-size: 1.7em;
  }

  h2 {
    font-size: 1.4em;
  }

  h4 {
    font-size: 1.4em;
  }
}

@media screen and (min-width: 1100px) {
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1.6em;
  }
}
