$color-primary: #f35c02;
$color-primary-hover: rgba(243,92,2, 0.8);
$background-color: linear-gradient( 109.6deg,  rgba(20,30,48,1) 11.2%, rgba(36,59,85,1) 91.1% );
$background-color-reverse: linear-gradient(#888888, #141313);

$font-primary: 'Montserrat', sans-serif;
$font-links: 'Karla', sans-serif;


$accordion-button-color: white;
$accordion-button-bg: $color-primary;
$accordion-button-active-color: white;
$accordion-button-active-bg: $color-primary;
$accordion-border-radius: 8px !important;
$accordion-inner-border-radius: 8px;

$nav-tabs-link-active-color: white;
$nav-tabs-link-active-bg: $color-primary;
$nav-tabs-link-active-border-color: $color-primary;

//Spinner color
$primary-color: $color-primary;