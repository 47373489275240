.about {
  max-width: 690px;
  margin: 1em auto;
  overflow-y: auto;

  @media screen and (min-width: 750px) {
    overflow-y: initial;
  }
}

.cv {
  font-size: 1.1em;
  margin: 1em;
  font-family: $font-links;

  a {
    color: $color-primary;

    &:hover {
      filter: brightness(1.2);
    }
  }
}
